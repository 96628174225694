import axios from 'axios';
import UploadForm from './UploadForm';
import ModalSuccess from './ModalSuccess';
import { IOrder } from '../PaparazzoTypes';
import { useState, useRef, useEffect } from 'react';
import { FileUploader, FileUploaderRef } from '../fileUploader/FileUploader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchTestate, selectTestateList } from '../../testate/TestateSlice';

interface UploadState {
  orderId: number;
  type: string;
  device: string;
  placement: string;
  urlClippedUI: string;
  isIntro: boolean;
  isSkin: boolean;
  testata: string;
  lineItem: string;
  selectedFile: File | null;
}

const ModalUploadScreenshot = ({
  orderId,
  orderDetail,
}: {
  orderId: string;
  orderDetail: IOrder;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTestate());
  }, [dispatch]);

  const testate = useAppSelector(selectTestateList);

  const [uploadState, setUploadState] = useState<UploadState>({
    orderId: +orderId,
    type: 'DISPLAY',
    device: 'DESKTOP',
    placement: '',
    urlClippedUI: '',
    isIntro: false,
    isSkin: false,
    testata: `${testate[0]?.baseURL}` || '',
    lineItem: `${orderDetail.lineItems[0]?.lineItem}` || '',
    selectedFile: null,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const fileUploaderRef = useRef<FileUploaderRef>(null);
  const displayTestate = testate.filter(
    (testata) => testata.type === uploadState.type,
  );

  const handleFileUpload = (file: File | null) => {
    setUploadState((prev) => ({ ...prev, selectedFile: file }));
  };

  const handleSubmit = async () => {
    if (
      !uploadState.selectedFile ||
      !uploadState.testata ||
      !uploadState.lineItem ||
      uploadState.testata === 'undefined'
    ) {
      alert('Per favore compila tutti i campi obbligatori');
      return;
    }

    try {
      const fileUrl = await fileUploaderRef.current?.uploadFile();
      if (!fileUrl) throw new Error('Errore nel caricamento del file');

      const { selectedFile, ...eventData } = {
        ...uploadState,
        urlClippedUI: fileUrl,
      };

      const encodedData = btoa(JSON.stringify(eventData));
      const response = await axios.get(
        `https://eu-central-1.aws.data.mongodb-api.com/app/paparazzoreporter-kroly/endpoint/copy/upload_event?event=${encodedData}`,
      );

      if (response.status === 200) {
        setShowSuccess(true);
      } else {
        throw new Error('Errore nella risposta del server');
      }
    } catch (error) {
      console.error('Errore durante il caricamento:', error);
      alert('Si è verificato un errore durante il caricamento');
    }
  };

  if (showSuccess) {
    return <ModalSuccess />;
  }

  return (
    <div className="modal-upload">
      <div className="modal-upload__row">
        <div className="">
          <div className="modal-upload__header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
              />
            </svg>
            <h3>Upload Screenshot</h3>
          </div>
          <div className="modal-upload__uploader">
            <FileUploader
              ref={fileUploaderRef}
              onFileUpload={handleFileUpload}
            />
          </div>
        </div>
        <UploadForm
          uploadState={uploadState}
          setUploadState={setUploadState}
          testate={displayTestate}
          orderDetail={orderDetail}
          onSubmit={handleSubmit}
          isSubmitDisabled={
            !uploadState.selectedFile ||
            !uploadState.placement ||
            !uploadState.device ||
            !uploadState.type ||
            !uploadState.testata ||
            uploadState.testata === 'undefined' ||
            !uploadState.lineItem
          }
        />
      </div>
    </div>
  );
};

export default ModalUploadScreenshot;
