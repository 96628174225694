import { IOrder } from '../PaparazzoTypes';
import { Testata } from '../../testate/TestateSlice';
import { SelectBox, SelectBoxItem } from '@tremor/react';

interface UploadState {
  orderId: number;
  type: string;
  device: string;
  placement: string;
  urlClippedUI: string;
  isIntro: boolean;
  isSkin: boolean;
  testata: string;
  lineItem: string;
  selectedFile: File | null;
}

interface UploadFormProps {
  uploadState: UploadState;
  setUploadState: React.Dispatch<React.SetStateAction<UploadState>>;
  testate: Testata[];
  orderDetail: IOrder;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
}

const UploadForm = ({
  uploadState,
  setUploadState,
  testate,
  orderDetail,
  onSubmit,
  isSubmitDisabled,
}: UploadFormProps) => {
  return (
    <div className="modal-upload__content">
      <div className="modal-upload__info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
          />
        </svg>
        <span>
          Compila i campi richiesti e clicca sul pulsante "Carica" per
          completare l'upload
        </span>
      </div>
      <form className="modal-upload__form" onSubmit={(e) => e.preventDefault()}>
        <div className="modal-upload__form-row">
          <label>
            <span>*</span>Tipologia
          </label>
          <SelectBox
            defaultValue={uploadState.type}
            onValueChange={(value) =>
              setUploadState((prev: UploadState) => ({
                ...prev,
                type: value || '',
              }))
            }
          >
            <SelectBoxItem value="DISPLAY" text="Display" />
            <SelectBoxItem value="PREROLL" text="Preroll" />
          </SelectBox>
        </div>
        <div className="modal-upload__form-row">
          <label>
            <span>*</span>Testata
          </label>
          <SelectBox
            defaultValue={uploadState.testata || ''}
            onValueChange={(value) =>
              setUploadState((prev: UploadState) => ({
                ...prev,
                testata: value || '',
              }))
            }
          >
            {testate?.map((testata) => (
              <SelectBoxItem
                key={testata.id}
                // @ts-ignore
                value={testata.testata || testata.baseURL || ''}
                // @ts-ignore
                text={testata.testata || testata.baseURL || ''}
              />
            ))}
          </SelectBox>
        </div>
        <div className="modal-upload__form-row">
          <label>
            <span>*</span>Line item
          </label>
          <SelectBox
            value={uploadState.lineItem}
            onValueChange={(value) =>
              setUploadState((prev: UploadState) => ({
                ...prev,
                lineItem: value || '',
              }))
            }
          >
            {orderDetail.lineItems.map((lineItem) => (
              <SelectBoxItem
                key={lineItem.id}
                value={lineItem.lineItem}
                text={lineItem.lineItem || ''}
              />
            ))}
          </SelectBox>
        </div>
        <div className="modal-upload__form-row">
          <label>
            <span>*</span>Device
          </label>
          <SelectBox
            defaultValue={uploadState.device}
            onValueChange={(value) =>
              setUploadState((prev: UploadState) => ({
                ...prev,
                device: value || '',
              }))
            }
          >
            <SelectBoxItem value="DESKTOP" text="Desktop" />
            <SelectBoxItem value="MOBILE" text="Mobile" />
            <SelectBoxItem value="TABLET" text="Tablet" />
          </SelectBox>
        </div>
        <div className="modal-upload__form-row">
          <label>
            <span>*</span>Placement
          </label>
          <SelectBox
            defaultValue={uploadState.placement}
            placeholder="Seleziona posizione"
            onValueChange={(value) =>
              setUploadState((prev: UploadState) => ({
                ...prev,
                placement: value || '',
              }))
            }
          >
            <SelectBoxItem value="Top" text="Top" />
            <SelectBoxItem value="TopLeft" text="TopLeft" />
            <SelectBoxItem value="Middle1" text="Middle1" />
            <SelectBoxItem value="Middle2" text="Middle2" />
            <SelectBoxItem value="Middle3" text="Middle3" />
            <SelectBoxItem value="Middle4" text="Middle4" />
            <SelectBoxItem value="Middle5" text="Middle5" />
            <SelectBoxItem value="Middle6" text="Middle6" />
            <SelectBoxItem value="Middle7" text="Middle7" />
            <SelectBoxItem value="Middle8" text="Middle8" />
            <SelectBoxItem value="Middle9" text="Middle9" />
            <SelectBoxItem value="Position3" text="Position3" />
            <SelectBoxItem value="Middle1-hr" text="Middle1-hr" />
            <SelectBoxItem value="Middle2-hr" text="Middle2-hr" />
            <SelectBoxItem value="Middle3-hr" text="Middle3-hr" />
            <SelectBoxItem value="x36" text="x36" />
            <SelectBoxItem value="BoxP" text="BoxP" />
            <SelectBoxItem value="Piede" text="Piede" />
            <SelectBoxItem value="Preroll" text="Preroll" />
          </SelectBox>
        </div>
        <div className="modal-upload__form-row">
          <label></label>
          <div className="modal-upload__form-row-checkboxes">
            <label className="modal-upload__form-row-checkboxes-label">
              <input
                type="checkbox"
                checked={uploadState.isIntro}
                onChange={(e) =>
                  setUploadState((prev: UploadState) => ({
                    ...prev,
                    isIntro: e.target.checked,
                  }))
                }
              />{' '}
              Intro
            </label>
            <label className="modal-upload__form-row-checkboxes-label">
              <input
                type="checkbox"
                checked={uploadState.isSkin}
                onChange={(e) =>
                  setUploadState((prev: UploadState) => ({
                    ...prev,
                    isSkin: e.target.checked,
                  }))
                }
              />{' '}
              Skin
            </label>
          </div>
        </div>
      </form>
      <div className="modal-upload__footer">
        <button className="cta" onClick={onSubmit} disabled={isSubmitDisabled}>
          Carica
        </button>
      </div>
    </div>
  );
};

export default UploadForm;
