interface ModalSuccessProps {
  message?: string;
}

const ModalSuccess = ({ message = 'Screenshot caricato con successo!' }: ModalSuccessProps) => {
  return (
    <div className="modal-upload">
      <div className="modal-upload__success">
        <div className="modal-upload__success-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-16 h-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
        <div className="modal-upload__success-message">
          {message}
        </div>
      </div>
    </div>
  );
};

export default ModalSuccess; 