import ModalForm from './modals/ModalForm';
import ModalDownload from './modals/ModalDownload';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setModalOpen,
  setSelectionEnabled,
  selectIsSelEnabled,
  selectSelectedForSendingLength,
} from './campaignsSlice';
import ModalUploadScreenshot from './modals/ModalUploadScreenshot';

const ButtonBar = (props: any) => {
  const dispatch = useAppDispatch();
  const isSelectionEnabled = useAppSelector(selectIsSelEnabled);
  const selectedForSendingLength = useAppSelector(
    selectSelectedForSendingLength,
  );

  return (
    <div className={props.className}>
      {!isSelectionEnabled ? (
        <>
          <div
            className="cta cta--outlined"
            onClick={() =>
              dispatch(
                setModalOpen(
                  <ModalUploadScreenshot
                    orderId={props.orderId} 
                    orderDetail={props.orderDetail}
                  />,
                ),
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#013DEE"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>
            <span>Upload Screenshot</span>
          </div>
          <div>
            <div
              className="cta"
              onClick={() => dispatch(setSelectionEnabled())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
              <span>Selezione multipla</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="selection__row">
            <div>
              <span>Seleziona gli elementi da inviare</span>
            </div>
            <div>
              <span>
                {props.itemsSelected} / {props.total}
              </span>
              <div
                className={`cta ${
                  selectedForSendingLength > 0 ? 'enabled' : 'disabled'
                }`}
                onClick={() =>
                  selectedForSendingLength > 0 &&
                  dispatch(
                    setModalOpen(
                      <ModalDownload
                        total={props.total}
                        screenshotsUrls={props.screenshotsUrls}
                      />,
                    ),
                  )
                }
              >
                <span>Download</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#ffffff"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                  />
                </svg>
              </div>
              <button
                className={`cta cta--outlined ${
                  selectedForSendingLength > 0 ? 'enabled' : 'disabled'
                }`}
                onClick={() =>
                  selectedForSendingLength > 0 &&
                  dispatch(
                    setModalOpen(
                      <ModalForm
                        attachedFiles={props.attachedFiles}
                        screenshotsUrls={props.screenshotsUrls}
                      />,
                    ),
                  )
                }
              >
                <span>Invia</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#ffffff"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ButtonBar;
