import Filters from '../filters/Filters';
import ButtonBar from './ButtonBar';
import SelectionBar from './SelectionBar';
import ScreenshotCard from './ScreenshotCard';
import {
  fetchOrder,
  resetEventFilter,
  selectActiveOrder,
  selectIsSelEnabled,
  resetSelectionEnabled,
  selectSelectedForSending,
  selectFilteredDeviceEvents,
  selectSelectedForSendingLength,
} from './campaignsSlice';
import { useParams } from 'react-router-dom';
import { IDeviceEvent } from './PaparazzoTypes';
import { fetchTestate, selectTestateList } from '../testate/TestateSlice';
import { screenshotBasePath } from '../../commons/config/settings';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const OrderDetails = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams();
  const orderDetail = useAppSelector(selectActiveOrder);
  const filteredArray = useAppSelector(
    selectFilteredDeviceEvents(orderId || '0'),
  );
  const selectedForSending = useAppSelector(selectSelectedForSending);
  const selectedForSendingLength = useAppSelector(
    selectSelectedForSendingLength,
  );
  const isSelectionEnabled = useAppSelector(selectIsSelEnabled);
  const urlsArray: Array<IDeviceEvent> = [];
  const uniqueTestate = orderDetail.deviceEvents
    ? orderDetail.deviceEvents.filter(
        (item: IDeviceEvent, index: number, self: any) =>
          index === self.findIndex((t: any) => t.testata === item.testata),
      )
    : [];
  filteredArray.forEach((deviceEvent) => {
    const date = deviceEvent.date;
    const screenshotUrl = `${screenshotBasePath + deviceEvent.urlClippedBase}`;
    const key = btoa(`${screenshotUrl}${date}`);
    selectedForSending.includes(key) && urlsArray.push(deviceEvent);
  });
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    console.log('---', orderId);
    if (orderId) {
      dispatch(fetchOrder(parseInt(orderId)));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSelectionEnabled());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetEventFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let className = 'order-detail__btns';
  if (scrollY > 200) {
    className = 'order-detail__btns order-detail__btns--sticky';
  }

  return (
    <div className="order-detail">
      <ButtonBar
        className={
          isSelectionEnabled
            ? 'order-detail__btns order-detail__btns--sticky selection-enabled'
            : className
        }
        itemsSelected={selectedForSendingLength}
        total={
          filteredArray.filter((d: IDeviceEvent) => d.urlClippedBase).length
        }
        attachedFiles={selectedForSendingLength}
        screenshotsUrls={urlsArray}
        orderId={orderId}
        orderDetail={orderDetail}
      />

      <div className="order-details__details details">
        {orderDetail && (
          <>
            <div className="details__row">
              <div
                className={
                  orderDetail.status?.toLowerCase() === 'delivering'
                    ? 'details__status details__status--active'
                    : 'details__status'
                }
              >
                {orderDetail.status?.toLowerCase() === 'delivering'
                  ? 'Attiva'
                  : 'Completata'}
              </div>
              <h2 className="details__order-id">{orderDetail.order}</h2>
            </div>
          </>
        )}

        {orderDetail && (
          <div className="details__row">
            <div className="details__col">
              <table>
                <tbody>
                  <tr>
                    <th>Cliente:</th>
                    <td>{orderDetail.mnzClientName}</td>
                  </tr>
                  <tr>
                    <th>Inizio campagna:</th>
                    <td>
                      {new Date(orderDetail.startDate).toLocaleDateString()}
                    </td>
                  </tr>
                  <tr>
                    <th>Fine campagna:</th>
                    <td>
                      {new Date(orderDetail.endDate).toLocaleDateString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="details__col">
              <table>
                <tbody>
                  <tr>
                    <th>N° Testate:</th>
                    <td>{uniqueTestate.length}</td>
                  </tr>
                  <tr>
                    <th>N° Screenshot Desktop:</th>
                    <td>{orderDetail.deviceEventsCount.desktop}</td>
                  </tr>
                  <tr>
                    <th>N° Screenshot Tablet:</th>
                    <td>{orderDetail.deviceEventsCount.tablet}</td>
                  </tr>
                  <tr>
                    <th>N° Screenshot Mobile:</th>
                    <td>{orderDetail.deviceEventsCount.mobile}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="details__col">
              <table>
                <tbody>
                  {/* <tr>
                                        <th>Order ID:</th>
                                        <td>{orderDetail.id}</td>
                                    </tr> */}
                  <tr>
                    <th>N° Line item:</th>
                    <td>{orderDetail.lineItemsCount}</td>
                  </tr>
                  <tr>
                    <th>Stato:</th>
                    <td>
                      {orderDetail.status?.toLowerCase() === 'delivering'
                        ? 'Attiva'
                        : 'Completata'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Filters />

      {isSelectionEnabled && (
        <SelectionBar
          itemsSelected={selectedForSendingLength}
          orderId={orderDetail && orderDetail.id}
          total={
            filteredArray.filter((d: IDeviceEvent) => d.urlClippedBase).length
          }
          array={
            orderDetail && orderDetail.deviceEvents.filter((d: any) => d.url)
          }
        />
      )}

      <span className="order-details__total">
        Risultati: {filteredArray.length}
      </span>

      <div
        className={`order-details__screenshots screenshots ${
          isSelectionEnabled ? 'selection-enabled' : ''
        }`}
      >
        {filteredArray.map((deviceEvent: IDeviceEvent, idx: number) => (
          <ScreenshotCard deviceEvent={deviceEvent} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default OrderDetails;
